<template>
  <v-card
    v-if="items"
    class="elevation-0 transparent rounded-lg"
    :disabled="loading"
    :loading="loading"
  >
    <v-toolbar flat class="mb-2 rounded-lg dborder" height="50px">
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        single-line
        solo
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
        style="width: 100%"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-hover v-slot="{ hover }">
        <v-btn
          height="28"
          :width="hover ? '' : '28'"
          :fab="hover ? false : true"
          small
          rounded
          color="primary"
          elevation="0"
          class="pushx"
        >
          <v-icon size="24">mdi-plus</v-icon>
          <v-expand-x-transition
            ><span v-show="hover"> add new</span></v-expand-x-transition
          >
        </v-btn>
      </v-hover>

      <v-btn
        small
        color="amber darken-1"
        class="white--text"
        @click="filters = !filters"
        elevation="0"
        rounded
      >
        <v-icon v-if="!filters" left dark class="mr-3"> mdi-filter </v-icon>
        <v-icon v-else left dark class="mr-3"> mdi-close </v-icon>filters
      </v-btn>
    </v-toolbar>
    <v-simple-table
      class="rounded-lg dborder mb-2"
      fixed-header
      height="calc(100vh - 285px)"
    >
      <template v-slot:default>
        <thead class="rounded-lg">
          <tr>
            <th class="text-left">User</th>
            <th class="text-left">Doctor</th>
            <th class="text-left">Status</th>
            <th class="text-left">Department</th>
            <th class="text-left">Asked on</th>
            <th class="text-left">Answered On</th>
          </tr>
        </thead>

        <tbody>
          <tr
            @click="
              selectedItem = item;
              showDetails = true;
            "
            v-for="item in items"
            :key="item.id"
          >
            <td>
              <v-avatar class="pushx" size="20" color="#F2F7FD">
                <v-img
                  v-if="item.owner.avatar"
                  :src="$root.asseturl + item.owner.avatar.private_hash"
                />

                <div
                  v-else
                  style="text-transform: uppercase; width: 100%"
                  class="primary--text"
                >
                  {{ item.owner.first_name.charAt(0) }}
                </div>
              </v-avatar>
              {{ item.owner.first_name }} {{ item.owner.last_name }}
            </td>
            <td>
              <template v-if="item.doctor">
                <v-avatar class="pushx" size="20" color="#F2F7FD">
                  <v-img
                    v-if="item.doctor.doctor_id.avatar"
                    :src="
                      $root.asseturl + item.doctor.doctor_id.avatar.private_hash
                    "
                  />

                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text"
                  >
                    {{ item.doctor.doctor_id.first_name.charAt(0) }}
                  </div>
                </v-avatar>
                {{ item.doctor.doctor_id.first_name }}
                {{ item.doctor.doctor_id.last_name }}
              </template>
            </td>
            <td>
              <span v-if="item.status == 'notanswered'">Not Answered</span>
              <span v-else>Answered</span>
            </td>
            <td>{{ item.department.name }}</td>
            <td>
              {{
                item.created_on
                  | moment(
                    "timezone",
                    $util.getTimezone(),
                    "YYYY/MM/DD hh:mm A"
                  )
              }}
            </td>
            <td>
              {{
                item.modified_on
                  | moment(
                    "timezone",
                    $util.getTimezone(),
                    "YYYY/MM/DD hh:mm A"
                  )
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div
      class="ma-0 pa-0 rounded-lg dborder d-flex align-center justify-end"
      style="height:40px; background-color:white"
    >
      <div v-if="pages !== -1" class="ma-0 pa-0 px-3">
        <v-btn
          @click="getpage(page - 1)"
          v-if="page !== 1"
          icon
          color="primary"
          small
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        page {{ page }} of {{ pages }}
        <v-btn
          @click="getpage(page + 1)"
          v-if="pages !== page"
          icon
          color="primary"
          small
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
    </div>

    <v-navigation-drawer
      v-model="showDetails"
      app
      temporary
      hide-overlay
      class="transparent elevation-0"
      width="40vw"
      style="border-radius: 0; border: none; padding: 10px"
      :right="$root.lang != 'ar'"
    >
      <v-card
        style="height: calc(100vh - 20px); max-height: calc(100vh - 20px)"
        flat
        class="dborder rounded-lg "
      >
        <v-toolbar
          flat
          color="rgb(238, 240, 248)"
          height="60"
          style="border-radius: 8px 8px 0px 0px"
        >
          <v-btn
            icon
            dark
            color="primary"
            @click="
              showDetails = false;
              selectedItem = null;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-primary"> Details </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          style="max-height: calc(100vh - 100px)"
          class="overflow-y-auto hidescroll"
        >
          <Details ref="itemDetails" />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="filters"
      app
      hide-overlay
      class=" elevation-0"
      width="25vw"
      style="border-radius: 0; border:none;"
      :right="$root.lang != 'ar'"
    >
      <v-card style="height:100vh; border-radius:0px" flat class="dborder">
        <QuestionsFilter @updatefilters="dofilter" @resetFilters="doreset" />
      </v-card>
    </v-navigation-drawer>

    <v-snackbar timeout="5000" v-model="snackbar" absolute bottom>
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import Details from "./Details.vue";
export default {
  components: {
    QuestionsFilter: () => import("../filters/QuestionsFilter.vue"),
    Details
  },
  data() {
    return {
      loading: false,
      page: 1,
      pages: -1,
      showDetails: false,
      items: [],
      formTitle: "Create Question",
      search: "",
      snackbar: false,
      msg: "",
      selectedItem: null,
      options: this.$question.getOptions(),
      filters: false,
      // details
      detailsTemplate: [
        { id: 1, key: "id", label: "ID", showas: "text" },
        { id: 2, key: "status", label: "Status", showas: "text" },
        { id: 3, key: "created_on", label: "Asked On", showas: "date" },
        { id: 4, key: "modified_on", label: "Answered On", showas: "date" },
        { id: 5, key: "department", label: "Department", showas: "avatar" },
        { id: 6, key: "owner", label: "Asked By", showas: "avatar" },
        { id: 7, key: "doctor", label: "Doctor", showas: "avatar" },
        { id: 9, key: "question", label: "Question", showas: "text" },
        { id: 10, key: "answer", label: "Answer", showas: "text" }
      ]
    };
  },

  async created() {},
  watch: {
    /*"$root.admin": {
      handler() {
        this.getUsers();
      },
      deep: true,
    },*/
    selectedItem(updated) {
      if (updated) {
        this.$refs.itemDetails.setItem(updated);
      } else {
        this.$refs.itemDetails.resetItem();
      }
    },
    showDetails(updated) {
      if (updated) {
        this.filters = false;
      }
    }
  },
  methods: {
    dofilter(data) {
      if (data.sort !== undefined) this.options.sort = data.sort;
      this.options.filter = data.filters;
      this.getItems();
    },
    doreset(data) {
      if (data) {
        this.$question.resetOptions();
        this.options = this.$question.getOptions();
        this.getItems();
      }
    },
    getpage(page) {
      this.options.page = page;
      this.getItems();
    },
    pageupdatehandler(e) {
      this.nodatatext = "Loading";
      this.items = [];
      this.loading = true;
      this.getItems(e);
    },
    showupdate(m) {
      this.snackbar = true;
      this.msg = m;
    },
    async getItems() {
      if (!this.loading) {
        this.loading = true;
        // console.log("in get items");

        // // console.log("loading new page", this.page);
        await this.$question.getItems(this.options).then(data => {
          console.log("new page data", data);
          this.pages = data.data.meta.page_count;
          this.page = data.data.meta.page;
          this.items = data.data.data;
          console.log(data.data.meta);
          // this.options.page++;
          this.loading = false;
        });
      }
    }
  },
  beforeMount() {},
  mounted() {
    this.getItems();
    this.$refs.itemDetails.setOptions(this.detailsTemplate);
    document.getElementsByClassName("v-data-table__wrapper")[0].className =
      "v-data-table__wrapper rounded-lg hidescroll";
  },
  computed: {
    itemsSorted() {
      return this.items.filter(i => {
        return (
          !this.sortBy ||
          i.status.toLowerCase() ===
            this.sortBy.replace(" ", "").toLowerCase() ||
          this.sortBy == "All"
        );
      });
    },
    searchresult() {
      return this.itemsSorted.filter(i => {
        return (
          !this.search ||
          (i.doctor.doctor_id.first_name + " " + i.doctor.doctor_id.last_name)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          (i.owner.first_name + " " + i.owner.last_name)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          i.created_on.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  }
};
</script>

<style scoped>
.theme--light >>> .v-data-footer__select {
  display: none !important;
}

.theme--light >>> .v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>
