<template>
  <div>
    <Appointments v-if="page === 'apps'" />
    <Questions v-else-if="page === 'questions'" />
    <Feed v-else-if="page === 'feed'" />
  </div>
</template>

<script>
import Appointments from "./comp/appointments.vue";
import Questions from "./comp/questions.vue";
import Feed from "./comp/feed.vue";

export default {
  components: {
    Appointments,
    Questions,
    Feed
  },
  data() {
    return {
      page: "apps"
    };
  },
  created() {
    this.$root.title = "Manage Data";
    this.$root.currentpage = "manage";
  },
  mounted() {
    this.$util.EventBus.$on("GlobalClick", data => {
      console.log("global click", data);
      switch (data) {
        case "manage-apps":
          this.page = "apps";
          break;
        case "manage-questions":
          this.page = "questions";
          break;
        case "manage-referral":
          this.page = "referral";
          break;
        case "manage-feed":
          this.page = "feed";
          break;
        default:
          this.page = "apps";
      }
    });
  },
  methods: {}
};
</script>

<style></style>
