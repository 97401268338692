<template>
  <v-card
    v-if="items"
    class="elevation-0 transparent rounded-lg"
    :disabled="loading"
    :loading="loading"
  >
    <v-toolbar flat class="mb-2 rounded-lg dborder" height="50px">
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        single-line
        solo
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
        style="width: 100%"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-hover v-slot="{ hover }">
        <v-btn
          height="28"
          :width="hover ? '' : '28'"
          :fab="hover ? false : true"
          small
          rounded
          @click="openitem(newItemTemplate)"
          color="primary"
          elevation="0"
          class="pushx"
        >
          <v-icon size="24">mdi-plus</v-icon>
          <v-expand-x-transition
            ><span v-show="hover"> add new</span></v-expand-x-transition
          >
        </v-btn>
      </v-hover>

      <v-btn
        v-if="items.length > 0"
        small
        color="amber darken-1"
        class="white--text"
        @click="filters = !filters"
        elevation="0"
        rounded
      >
        <v-icon v-if="!filters" left dark class="mr-3"> mdi-filter </v-icon>
        <v-icon v-else left dark class="mr-3"> mdi-close </v-icon>filters
      </v-btn>
    </v-toolbar>
    <v-simple-table
      class="rounded-lg dborder mb-2"
      fixed-header
      height="calc(100vh - 285px)"
    >
      <template v-slot:default>
        <thead class="rounded-lg">
          <tr>
            <th
              class="text-left"
              v-for="header in detailsTemplate"
              :key="header.id + 'h'"
            >
              {{ header.label }}
            </th>
          </tr>
        </thead>

        <tbody v-if="items.length > 0">
          <tr @click="openitem(item)" v-for="item in items" :key="item.id">
            <td v-for="template in detailsTemplate" :key="template.id">
              <template v-if="template.showas === 'avatar'">
                <v-avatar class="pushx" size="20" color="#F2F7FD">
                  <v-img
                    v-if="item[template.key].avatar"
                    :src="
                      $root.asseturl + item[template.key].avatar.private_hash
                    "
                  />

                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text"
                  >
                    {{ item[template.key].first_name.charAt(0) }}
                  </div>
                </v-avatar>
                {{ item[template.key].first_name }}
                {{ item[template.key].last_name }}
              </template>
              <template v-else-if="template.showas === 'text'">
                {{ item[template.key] }}
              </template>
              <template v-else-if="template.showas === 'date'">
                {{
                  item[template.key]
                    | moment(
                      "timezone",
                      $util.getTimezone(),
                      "YYYY/MM/DD hh:mm A"
                    )
                }}
              </template>
              <template v-else-if="template.showas === 'likes'">
                <span :key="item.localid">{{ item[template.key] }}</span>
              </template>
              <template v-else-if="template.showas === 'lang'">
                <v-avatar
                  v-for="t in item[template.key]"
                  :key="t.id"
                  class="pushx rounded light-blue lighten-5"
                  tile
                  size="25"
                >
                  <v-img
                    contain
                    height="10px"
                    width="10px"
                    :src="asseturl + getflag(t.lang)"
                  />
                </v-avatar>
              </template>
            </td>
          </tr>
        </tbody>
        <tr
          :colspan="detailsTemplate.length"
          style="height: calc((100vh - 285px) - 48px);text-align: center;"
          v-else-if="!loading"
        >
          <td colspan="7">
            no items found
          </td>
        </tr>
      </template>
    </v-simple-table>
    <div
      class="ma-0 pa-0 rounded-lg dborder d-flex align-center justify-end"
      style="height: 40px; background-color: white"
    >
      <div v-if="pages !== -1" class="ma-0 pa-0 px-3">
        <v-btn
          @click="getpage(page - 1)"
          v-if="page !== 1"
          icon
          color="primary"
          small
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        page {{ page }} of {{ pages }}
        <v-btn
          @click="getpage(page + 1)"
          v-if="pages !== page"
          icon
          color="primary"
          small
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
    </div>

    <v-navigation-drawer
      v-model="showDetails"
      app
      temporary
      width="60vw"
      style="border-radius: 0"
      :right="$root.lang != 'ar'"
    >
      <v-toolbar
        v-if="selectedItem"
        color="rgb(238, 240, 248)"
        style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
        height="60"
      >
        <v-btn icon dark color="primary" @click="closeItem()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-primary">
          {{ selectedItem.title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          elevation="0"
          @click="takeAction()"
          rounded
          height="40px"
          color="rgba(165, 203, 239, 0.20)"
        >
          <unicon
            style="margin-right: auto; margin-left: auto"
            name="save"
            fill="#0971CE"
            height="20"
          ></unicon>
          <span style="color: #0971ce"
            ><span v-if="selectedItem.id">Update</span><span v-else>Post</span>
            {{ selectedItem.type }}</span
          >
        </v-btn>
        <v-btn
          v-if="selectedItem.id"
          small
          elevation="0"
          @click="deleteItem()"
          rounded
          height="40px"
          color="rgba(239, 165, 179, 0.25)"
        >
          <unicon
            style="margin-right: auto; margin-left: auto"
            name="trash-alt"
            fill="#f64e60"
            height="20"
          ></unicon>
          <span style="color: #f64e60">Delete {{ selectedItem.type }}</span>
        </v-btn>
      </v-toolbar>

      <v-card
        :loading="actionLoading"
        :disabled="actionLoading"
        v-if="selectedItem"
        flat
      >
        <perfect-scrollbar
          class="scroll"
          style="position: relative; max-height: calc(100vh - 60px)"
        >
          <div class="pa-7">
            <div class="d-flex align-center">
              <v-select
                flat
                hide-details
                outlined
                color="primary"
                :items="type"
                :label="'Type'"
                v-model="selectedItem.type"
                style="max-width: 200px !important"
              ></v-select>
              <v-select
                flat
                hide-details
                outlined
                color="primary"
                :items="status"
                :label="'Status'"
                class="mx-2"
                v-model="selectedItem.status"
                style="max-width: 200px !important"
              ></v-select>

              <v-select
                flat
                hide-details
                outlined
                color="primary"
                :items="langs"
                :label="'Language'"
                v-model="selectedlang"
                style="width: 200px !important"
                item-text="name"
                item-value="id"
              >
                <template v-slot:selection="data">
                  <v-avatar
                    class="pushx rounded light-blue lighten-5"
                    tile
                    size="25"
                  >
                    <v-img
                      contain
                      height="10px"
                      width="10px"
                      :src="asseturl + getflag(data.item.code)"
                    />
                  </v-avatar>
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  <v-avatar
                    class="pushx rounded light-blue lighten-5"
                    tile
                    size="25"
                  >
                    <v-img
                      contain
                      height="10px"
                      width="10px"
                      :src="asseturl + getflag(data.item.code)"
                    />
                  </v-avatar>
                  {{ data.item.name }}
                </template>
              </v-select>
            </div>
            <v-file-input
              v-if="selectedItem.type !== 'advice'"
              v-model="selectedItem.cover"
              class="my-4"
              placeholder="Cover"
              outlined
              hide-details
              label="Upload Cover"
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
            >
              <template v-slot:selection="{}">
                <v-img
                  contain
                  max-height="200"
                  max-width="50vw"
                  :src="
                    selectedItem.cover.private_hash
                      ? asseturl + selectedItem.cover.private_hash
                      : processedImg
                  "
                />
              </template>
            </v-file-input>
            <v-autocomplete
              v-if="
                $user.getCurrentUser().role.id === 1 && this.selectedItem.id
              "
              v-model="owner"
              clearable
              class="my-4"
              hide-details
              outlined
              :items="$doctor.getLocalDocs()"
              :filter="doctorFilter"
              label="Auther"
              return-object
            >
              <template v-slot:selection="data">
                {{ data.item.doctor_id.first_name }}
                {{ data.item.doctor_id.last_name }}
              </template>
              <template v-slot:item="data">
                {{ data.item.doctor_id.first_name }}
                {{ data.item.doctor_id.last_name }}
              </template>
            </v-autocomplete>

            <v-text-field
              class="my-2"
              :class="translationToShow.lang === 'ar' ? 'rtl' : 'ltr'"
              label="title"
              outlined
              hide-details
              v-model="translationToShow.title"
            />
            <v-text-field
              class="my-4"
              :class="translationToShow.lang === 'ar' ? 'rtl' : 'ltr'"
              label="summery"
              outlined
              hide-details
              v-model="translationToShow.summery"
            />

            <div v-if="selectedItem.type !== 'advice'">
              <div v-if="editor" class="parmenu">
                <button
                  class="first"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleBold()
                      .run()
                  "
                  :class="editor.isActive('bold') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('bold') ? 'white' : ''"
                    >mdi-format-bold</v-icon
                  >
                </button>

                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleItalic()
                      .run()
                  "
                  :class="editor.isActive('italic') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('italic') ? 'white' : ''"
                    >mdi-format-italic</v-icon
                  >
                </button>

                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleUnderline()
                      .run()
                  "
                  :class="editor.isActive('underline') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('underline') ? 'white' : ''"
                    >mdi-format-underline</v-icon
                  >
                </button>

                <button
                  class="last pushx"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleStrike()
                      .run()
                  "
                  :class="editor.isActive('strike') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('strike') ? 'white' : ''"
                    >mdi-format-strikethrough</v-icon
                  >
                </button>

                <button
                  class="first"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .setTextAlign('left')
                      .run()
                  "
                  :class="
                    editor.isActive({ textAlign: 'left' }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive({ textAlign: 'left' }) ? 'white' : ''
                    "
                    >mdi-format-align-left</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .setTextAlign('center')
                      .run()
                  "
                  :class="
                    editor.isActive({ textAlign: 'center' }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive({ textAlign: 'center' }) ? 'white' : ''
                    "
                    >mdi-format-align-center</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .setTextAlign('right')
                      .run()
                  "
                  :class="
                    editor.isActive({ textAlign: 'right' }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive({ textAlign: 'right' }) ? 'white' : ''
                    "
                    >mdi-format-align-right</v-icon
                  >
                </button>
                <button
                  class="last pushx"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .setTextAlign('justify')
                      .run()
                  "
                  :class="
                    editor.isActive({ textAlign: 'justify' }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive({ textAlign: 'justify' }) ? 'white' : ''
                    "
                    >mdi-format-align-justify</v-icon
                  >
                </button>

                <button
                  class="first"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleHeading({ level: 1 })
                      .run()
                  "
                  :class="
                    editor.isActive('heading', { level: 1 }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 1 }) ? 'white' : ''
                    "
                    >mdi-format-header-1</v-icon
                  >
                </button>

                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleHeading({ level: 2 })
                      .run()
                  "
                  :class="
                    editor.isActive('heading', { level: 2 }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 2 }) ? 'white' : ''
                    "
                    >mdi-format-header-2</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleHeading({ level: 3 })
                      .run()
                  "
                  :class="
                    editor.isActive('heading', { level: 3 }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 3 }) ? 'white' : ''
                    "
                    >mdi-format-header-3</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleHeading({ level: 4 })
                      .run()
                  "
                  :class="
                    editor.isActive('heading', { level: 4 }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 4 }) ? 'white' : ''
                    "
                    >mdi-format-header-4</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleHeading({ level: 5 })
                      .run()
                  "
                  :class="
                    editor.isActive('heading', { level: 5 }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 5 }) ? 'white' : ''
                    "
                    >mdi-format-header-5</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleHeading({ level: 6 })
                      .run()
                  "
                  :class="
                    editor.isActive('heading', { level: 6 }) ? 'primary' : ''
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 6 }) ? 'white' : ''
                    "
                    >mdi-format-header-6</v-icon
                  >
                </button>

                <button
                  class="last pushx"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .setParagraph()
                      .run()
                  "
                  :class="editor.isActive('paragraph') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('paragraph') ? 'white' : ''"
                    >mdi-format-paragraph</v-icon
                  >
                </button>

                <button
                  class="first"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleBulletList()
                      .run()
                  "
                  :class="editor.isActive('bulletList') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('bulletList') ? 'white' : ''"
                    >mdi-format-list-bulleted</v-icon
                  >
                </button>
                <button
                  class="last pushx"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .toggleOrderedList()
                      .run()
                  "
                  :class="editor.isActive('orderedList') ? 'primary' : ''"
                >
                  <v-icon :color="editor.isActive('orderedList') ? 'white' : ''"
                    >mdi-format-list-numbered</v-icon
                  >
                </button>

                <button
                  class="first"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .unsetAllMarks()
                      .run()
                  "
                >
                  <v-icon :color="editor.isActive('orderedList') ? 'white' : ''"
                    >mdi-format-clear</v-icon
                  >
                </button>
                <button
                  @click="
                    editor
                      .chain()
                      .focus()
                      .undo()
                      .run()
                  "
                >
                  <v-icon :color="editor.isActive('orderedList') ? 'white' : ''"
                    >mdi-undo</v-icon
                  >
                </button>
                <button
                  class="last pushx"
                  @click="
                    editor
                      .chain()
                      .focus()
                      .redo()
                      .run()
                  "
                >
                  <v-icon :color="editor.isActive('orderedList') ? 'white' : ''"
                    >mdi-redo</v-icon
                  >
                </button>
              </div>
              <editor-content
                :class="translationToShow.lang === 'ar' ? 'rtl' : 'ltr'"
                class="mt-5"
                style="min-height: 300px"
                :editor="editor"
              />
            </div>
          </div>
        </perfect-scrollbar>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="filters"
      app
      hide-overlay
      class="elevation-0"
      width="25vw"
      style="border-radius: 0; border: none"
      :right="$root.lang != 'ar'"
    >
      <v-card style="height: 100vh; border-radius: 0px" flat class="dborder">
        <FeedFilter @updatefilters="dofilter" @resetFilters="doreset" />
      </v-card>
    </v-navigation-drawer>
    <v-snackbar timeout="5000" v-model="snackbar" absolute top>
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";

export default {
  components: {
    EditorContent,
    FeedFilter: () => import("../filters/FeedFilter.vue")
  },
  name: "posts",
  data() {
    return {
      loading: false,
      page: 1,
      pages: -1,
      options: this.$feed.getOptions(),
      filters: false,
      actionLoading: false,
      editor: null,
      file: null,
      owner: null,
      showDetails: false,
      nodatatext: "No Data Available",
      thumbnail: "",
      detailsTemplate: [
        { id: 1, key: "owner", label: "By", showas: "avatar" },
        { id: 2, key: "status", label: "Status", showas: "text" },
        { id: 3, key: "title", label: "Title", showas: "text" },
        { id: 4, key: "type", label: "Type", showas: "text" },
        { id: 5, key: "translation", label: "Languages", showas: "lang" },
        { id: 6, key: "created_on", label: "Created On", showas: "date" },
        { id: 7, key: "likes", label: "❤️", showas: "likes" }
      ],

      items: [],
      formTitle: "Add Feed Item",
      search: "",
      sortBy: "",
      keys: ["All", "Published", "Draft", "Deleted"],
      status: ["published", "draft", "deleted"],
      type: ["article", "advice"],
      snackbar: false,
      msg: "",
      selectedItem: null,
      langs: [],
      asseturl: process.env.VUE_APP_api_url + "assets/",
      selectedlang: null,
      tindex: 0,
      translationToShow: { lang: this.$root.lang },
      newItemTemplate: {
        type: "article",
        title: "",
        translation: [{ lang: this.$root.lang }]
      }
    };
  },

  async created() {
    this.langs = this.$util.getLanguages();
  },
  watch: {
    showDetails(updated) {
      if (updated) {
        this.filters = false;
      } else {
        this.closeItem();
      }
    },
    selectedlang(updated) {
      if (updated) {
        let lang = this.langs.find(l => {
          return l.id == updated;
        });
        // console.log("lang is", lang, updated);
        this.updateTranslation();
        let toset = this.selectedItem.translation.find(t => {
          return t.lang === lang.code;
        });
        // console.log("toset", toset);
        if (toset != undefined) {
          this.translationToShow = toset;
        } else
          this.translationToShow = {
            lang: lang.code,
            content: "",
            summery: "",
            title: ""
          };
        // console.log("ready for editor and t is", this.translationToShow);
        if (this.editor) this.editor.destroy();

        this.editor = new Editor({
          content: this.setContent(),
          extensions: [
            StarterKit,
            Document,
            Paragraph,
            Text,
            Underline,
            TextAlign.configure({
              types: ["heading", "paragraph"]
            })
          ],
          onFocus: e => {
            if (e.editor.getHTML() === "<h3>Write Here</h3>")
              e.editor.commands.clearContent();
          },
          injectCSS: false,
          defaultAlignment:
            this.translationToShow.lang === "ar" ? "right" : "left",
          editable: true,
          onUpdate: e => {
            this.translationToShow.content = e.editor.getHTML();
          }
        });
        this.$forceUpdate();
      }
    }
  },

  methods: {
    doctorFilter(doctor, queryText) {
      return `${doctor.doctor_id.first_name} ${doctor.doctor_id.last_name}`
        .toLowerCase()
        .includes(queryText.toLowerCase());
    },
    setContent() {
      return this.translationToShow.content
        ? this.translationToShow.content
        : "<h3>Write Here</h3>";
    },
    async takeAction() {
      this.actionLoading = true;
      this.updateTranslation();
      // console.log("item to process", this.selectedItem);
      let isnew = this.selectedItem.id ? false : true;
      if (isnew) {
        if (this.selectedItem.cover) {
          var formData = new FormData();
          formData.append("data", this.selectedItem.cover);
          const { data } = await this.$api.simplePostFile("files", formData);
          //// console.log("file upload result id",data.data.id)
          this.selectedItem.cover = data.data.id;
          this.thumbnail = data.data.private_hash;
        }
        this.selectedItem.status = this.selectedItem.status
          ? this.selectedItem.status
          : "published";

        this.selectedItem.translation = this.selectedItem.translation.filter(
          t => {
            return t.title && t.summery;
          }
        );
        this.selectedItem.title = this.selectedItem.translation[0].title;
        this.$feed.postItem(this.selectedItem).then(data => {
          if (data.status === 200) {
            this.showupdate("Item Posted Succssfully");
            this.showDetails = false;
            this.actionLoading = false;
            this.getItems();
            if (this.owner) {
              this.$feed
                .updateItem(data.data.data.id, {
                  owner: this.owner.doctor_id.id
                })
                .then(() => {
                  // console.log("patch feed item ",data)
                  this.owner = null;
                });
            }
          } else {
            this.showupdate(
              "Problem posting your " +
                this.selectedItem.type +
                ". Try again in a bit"
            );
            this.actionLoading = false;
          }
        });
      } else {
        // console.log("updating here");
        let payload = {
          status: this.selectedItem.status,
          title: this.selectedItem.translation[0].title
        };
        let newtranslation = [];
        let toupdatetranslation = [];
        if (
          this.selectedItem.cover !== undefined &&
          this.selectedItem.cover !== null &&
          !this.selectedItem.cover.private_hash
        ) {
          formData = new FormData();
          formData.append("data", this.selectedItem.cover);
          const { data } = await this.$api.simplePostFile("files", formData);
          //// console.log("file upload result id",data.data.id)
          payload.cover = data.data.id;
          this.thumbnail = data.data.private_hash;
        } else if (this.selectedItem.cover === undefined) {
          payload.cover = null;
        }
        this.selectedItem.translation.forEach(item => {
          if (item.id) toupdatetranslation.push(item);
          else {
            item.feed_item = this.selectedItem.id;
            newtranslation.push(item);
          }
        });
        // console.log("item to process", payload, "new translation",newtranslation);
        newtranslation.forEach(item => {
          this.$feed.postTranslation(item);
        });
        toupdatetranslation.forEach(item => {
          this.$feed.updateTranslationItem(item.id, item);
        });
        this.$feed.updateItem(this.selectedItem.id, payload).then(data => {
          if (data.status === 200) {
            this.showupdate("Item updated Succssfully");
            this.showDetails = false;
            this.actionLoading = false;
          } else {
            this.showupdate(
              "Problem updating your " +
                this.selectedItem.type +
                ". Try again in a bit"
            );
            this.actionLoading = false;
          }
        });
      }
    },
    updateTranslation() {
      if (this.translationToShow.title != "") {
        let index = this.selectedItem.translation.findIndex(t => {
          return t.lang === this.translationToShow.lang;
        });
        if (index != -1) {
          if (
            this.translationToShow.title &&
            this.translationToShow.title != "" &&
            this.translationToShow.summery &&
            this.translationToShow.summery != ""
          ) {
            this.selectedItem.translation[index] = this.translationToShow;
          }
        } else {
          this.selectedItem.translation.push(this.translationToShow);
        }
      }
    },
    deleteItem() {
      this.actionLoading = true;
      this.selectedItem.translation = this.selectedItem.translation.filter(
        t => {
          return t.title && t.summery;
        }
      );
      // console.log("item to process", this.selectedItem);
      this.$feed.deleteItem(this.selectedItem).then(data => {
        if (data.status === 204) {
          let index = this.items.findIndex(i => {
            return i.id === this.selectedItem.id;
          });
          this.items.splice(index, 1);
          this.showupdate("Item deleted Succssfully");
          this.showDetails = false;
          this.actionLoading = false;
        } else {
          this.showupdate(
            "Problem deleting your " +
              this.selectedItem.type +
              ". Try again in a bit"
          );
          this.actionLoading = false;
        }
      });
    },
    openitem(i) {
      this.showDetails = true;
      this.selectedItem = i;

      this.selectedlang = this.artical_lang;
    },
    closeItem() {
      if (this.selectedItem) {
        this.selectedItem.translation = this.selectedItem.translation.filter(
          t => {
            return t.title && t.summery;
          }
        );
      }

      this.showDetails = false;
      this.selectedItem = null;
      this.tindex = 0;
      this.translationToShow = { lang: this.$root.lang };
      this.selectedlang = null;
      this.editor.destroy();
    },
    getflag(lang) {
      return this.langs.find(l => {
        return l.code === lang;
      }).icon;
    },
    pageupdatehandler(e) {
      this.nodatatext = "Loading";
      this.items = [];
      this.loading = true;
      this.getItems(e);
    },
    showupdate(m) {
      this.snackbar = true;
      this.msg = m;
    },
    /*async getItems(page = 1) {
      await this.$feed
        .getFeedItems(
          page,
          this.$user.getCurrentUser().role.id == 3
            ? this.$user.getCurrentUser().id
            : null
        )
        .then((data) => {
          this.loading = false;
          // console.log("items are", data);
          this.items = data;
          this.nodatatext = "No Data Available";
        });
    },*/
    dofilter(data) {
      if (data.sort !== undefined) this.options.sort = data.sort;
      this.options.filter = data.filters;
      this.getItems();
    },
    doreset(data) {
      if (data) {
        this.$feed.resetOptions();
        this.options = this.$feed.getOptions();
        if (this.$user.getCurrentUser().role.id == 3) {
          this.options.filter.owner = {
            operator: "eq",
            value: this.$user.getCurrentUser().id
          };
        }
        this.getItems();
      }
    },
    getpage(page) {
      this.options.page = page;
      this.getItems();
    },
    async getItems() {
      if (!this.loading) {
        this.loading = true;
        // console.log("in get items");

        // // console.log("loading new page", this.page);
        await this.$feed.getItems(this.options).then(data => {
          console.log("new page data", data);
          this.pages = data.data.meta.page_count;
          this.page = data.data.meta.page;
          data.data.data.map(item => {
            item.localid = item.id;
            return item;
          });
          this.items = data.data.data;
          // console.log(data.data.meta);
          // this.options.page++;
          this.loading = false;
        });
      }
    }
  },
  mounted() {
    if (this.$user.getCurrentUser().role.id == 3) {
      this.options.filter.owner = {
        operator: "eq",
        value: this.$user.getCurrentUser().id
      };
    }

    this.getItems();
    this.$util.EventBus.$on("updatefeedpage", e => {
      let index = this.items.findIndex(item => {
        return item.id === e;
      });
      if (index != -1) this.items[index].localid = "l" + e;
      // this.$forceUpdate();
    });
    document.getElementsByClassName("v-data-table__wrapper")[0].className =
      "v-data-table__wrapper rounded-lg hidescroll";
  },
  beforeDestroy() {},
  computed: {
    processedImg() {
      if (
        this.selectedItem.cover &&
        !this.selectedItem.cover.private_hash &&
        !Number.isInteger(this.selectedItem.cover)
      ) {
        return URL.createObjectURL(this.selectedItem.cover);
      } else if (Number.isInteger(this.selectedItem.cover))
        return this.asseturl + this.thumbnail;
      else return null;
    },
    artical_lang() {
      return this.langs.find(l => {
        return this.selectedItem.translation[0].lang === l.code;
      }).id;
    },
    itemsSorted() {
      return this.items.filter(i => {
        return (
          !this.sortBy ||
          i.status.toLowerCase() === this.sortBy.toLowerCase() ||
          this.sortBy == "All"
        );
      });
    },
    searchresult() {
      return this.itemsSorted.filter(i => {
        return (
          !this.search ||
          (i.owner.first_name + " " + i.owner.last_name)
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          i.created_on.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  }
};
</script>

<style lang="scss">
.rtl {
  .parmenu {
    .first {
      border-radius: 0px 5px 5px 0px;
    }
    .last {
      border-radius: 5px 0px 0px 5px;
    }
  }
}
.parmenu {
  button {
    border-width: 2px;
    background-color: #dfdfdf;
    padding: 5px;
  }
  .active {
    background-color: #0971ce;
    color: white;
  }
  .first {
    border-radius: 5px 0px 0px 5px;
  }
  .last {
    border-radius: 0px 5px 5px 0px;
  }
}
/* Basic editor styles */
.ProseMirror-focused {
  outline: none;
}
.ProseMirror {
  min-height: 300px;
  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
<style scoped>
.theme--light >>> .v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>
