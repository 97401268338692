<template>
  <v-simple-table v-if="selectedItem">
    <template v-slot:default>
      <tbody>
        <tr v-for="item in options" :key="item.id">
          <td class="font-weight-boldest font-size-h6">
            {{ item.label }}
          </td>
          <td class="font-size-h6">
            <template v-if="item.showas === 'date'">
              <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
                $util.processTime(selectedItem[item.key])
                  | moment(
                    "timezone",
                    $util.getTimezone(),
                    "YYYY/MM/DD HH:mm A"
                  )
              }}</span
              ><br />
              <span class="text-dark-75 font-weight-light font-size-sm"
                >({{
                  $util.processTime(selectedItem[item.key])
                    | moment("timezone", $util.getTimezone(), "from", "now")
                }})</span
              >
            </template>
            <template v-else-if="item.showas === 'avatar'">
              <div v-if="item.key === 'medical_file'">
                <v-avatar class="pushx" size="20" color="#F2F7FD">
                  <v-img
                    v-if="selectedItem[item.key].owner.avatar"
                    :src="
                      $root.asseturl +
                        selectedItem[item.key].owner.avatar.private_hash
                    "
                  />

                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text"
                  >
                    {{ selectedItem[item.key].owner.first_name.charAt(0) }}
                  </div>
                </v-avatar>
                {{ selectedItem[item.key].owner.first_name }}
                {{ selectedItem[item.key].owner.last_name }}
              </div>
              <div v-else-if="item.key === 'owner'">
                <v-avatar class="pushx" size="20" color="#F2F7FD">
                  <v-img
                    v-if="selectedItem[item.key].avatar"
                    :src="
                      $root.asseturl +
                        selectedItem[item.key].avatar.private_hash
                    "
                  />

                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text"
                  >
                    {{ selectedItem[item.key].first_name.charAt(0) }}
                  </div>
                </v-avatar>
                {{ selectedItem[item.key].first_name }}
                {{ selectedItem[item.key].last_name }}
              </div>
              <div v-else-if="item.key === 'doctor'">
                <template v-if="selectedItem.doctor">
                  <v-avatar class="pushx" size="20" color="#F2F7FD">
                    <v-img
                      v-if="selectedItem[item.key].doctor_id.avatar"
                      :src="
                        $root.asseturl +
                          selectedItem[item.key].doctor_id.avatar.private_hash
                      "
                    />

                    <div
                      v-else
                      style="text-transform: uppercase; width: 100%"
                      class="primary--text"
                    >
                      {{
                        selectedItem[item.key].doctor_id.first_name.charAt(0)
                      }}
                    </div>
                  </v-avatar>
                  {{ selectedItem[item.key].doctor_id.first_name }}
                  {{ selectedItem[item.key].doctor_id.last_name }}
                </template>
              </div>
              <div v-else-if="item.key === 'department'">
                <v-avatar class="pushx" size="20" color="#F2F7FD">
                  <v-img
                    v-if="selectedItem[item.key].avatar"
                    :src="
                      $root.asseturl +
                        selectedItem[item.key].avatar.private_hash
                    "
                  />

                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text"
                  >
                    {{ selectedItem[item.key].name.charAt(0) }}
                  </div>
                </v-avatar>
                {{ selectedItem[item.key].name }}
              </div>
            </template>
            <template v-else-if="item.key === 'log'">
              <v-timeline v-if="selectedItem.log" align-top dense>
                <v-timeline-item
                  v-for="logitem in selectedItem.log"
                  :key="logitem.time"
                  color="primary"
                  small
                >
                  <v-row class="pa-0 ma-0">
                    <v-col class="pa-0 ma-0 text-caption">
                      {{
                        logitem.time
                          | moment("timezone", $util.getTimezone(), "HH:mm A")
                      }}
                      <div class="text-caption">
                        {{ logitem.log }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </template>
            <template v-else>
              {{ selectedItem[item.key] }}
            </template>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      selectedItem: null,
      options: null
    };
  },

  async created() {},
  watch: {},
  methods: {
    reset() {
      this.selectedItem = null;
      this.options = null;
    },
    setOptions(options) {
      this.options = options;
    },
    setItem(item) {
      this.selectedItem = item;
    },
    resetItem() {
      this.selectedItem = null;
    },
    resetOptions() {
      this.options = null;
    }
  },
  beforeMount() {},
  mounted() {},
  computed: {}
};
</script>
